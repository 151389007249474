export default {
  route: {
    'home': '首頁',
    'mine': '個人中心',
    'search': '覆蓋超過140+國家和地區',
    'login': '登錄',
    'logout': '退出登錄',
    'user':'個人信息',
    'wallet':'我的錢包',
    'order':'我的訂單',
    'share':'分銷訂單',
    'flow':'我的套餐',
    'coupon':'優惠券',
    'invite':'邀請有禮',
    'faq':'使用幫助',
    'device':'支持設備',
    'guide':'安裝指南',
  },
  qrcode:{
    'lang':'切換語言',
    'title':'訂單詳細資料',
    'guide':'使用教程',
    'days': '天',
    'empty':'套餐創建中，如果您有任何疑問，請聯系我們。',
    'subtitle_1':'使用eSIM方法一：掃碼QR碼',
    'subtitle_2':'使用eSIM方法二：手動輸入QR碼信息',
    'time':'(為了獲得更好的服務體驗 請在 {value} 前激活使用)',
    'label_1':'SM-DP+地址',
    'label_2':'激活碼',
    'label_3':'您的卡號：',
    'label_4':'訂單編號：',
    'install':'快速安裝'
  },
  common:{
    'qantas_title':'澳門航空獨家禮遇',
    'qantas_official':'進入澳航官網了解詳情',
    'qantas_get':'領取優惠',
    'general_discounts':'全場滿減',
    'data_plan_offers':'套餐優惠',
    'download_tips':'請從右上角選擇瀏覽器打開下載',
    'cancel':'取消',
    'ok':'確認',
    'all':'全部套餐',
    'hot':'熱門地區',
    'area':'覆蓋地區',
    'area_global':'進入官網 · 玩轉地球',
    'other':'其他套餐',
    'slogan':'環旅上網，即刻連接',
    'price':'價格',
    'discount':'折扣',
    'from':'低至 ',
    'explain':'詳細操作步驟',
    'title_1':'套餐規格：',
    'title_2':'套餐天數：',
    'title_3':'套餐說明',
    'title_4':'套餐介紹：',
    'title_5':'使用說明',
    'title_6':'套餐詳情',
    'buy_now':'立即購買',
    'compatible_text1':'我的設備支持eSIM嗎？',
    'compatible_text2':'一步查询！',
    'tips_1':'請確認設備無SIM卡限制：可于“設置 > 一般 > 關於本機 ”中查看電訊商鎖狀態',
    'tips_2':'請確認設備支持eSIM：撥打 *#06#，若顯示“EID”序列，則代表您的移動設備支持eSIM。',
    'pay_1':'確認訂單',
    'pay_2':'訂單信息',
    'pay_3':'最晚使用日期：',
    'pay_4':'請選擇支付方式',
    'pay_5':'應付金額：',
    'pay_6':'確認付款',
    'pay_7':'訂單金額：',
    'pay_8':'訂單號：',
    'pay_9':'訂單提交成功，請您盡快完成付款！',
    'pay_10':'取消支付',
    'pay_11':'我已完成支付',
    'pay_12':'查詢訂單中，請稍候',
    'pay_13':'共減：',
    'pay_14':'商品總價：',
    'pay_15':'已优惠：',
    'search_1':'搜索關鍵字：',
    'search_2':'搜索結果',
    'search_3':'没有找到',
    'search_4':'請輸入關鍵字',
    'check_order':'查看訂單',
    'pay_again': '重新支付',
    'back_home':'回到首頁',
    'result_success':'支付成功',
    'result_fail':'支付失敗',
    'p_result_tips':'訂單號: {text} eSIM配置需要1-3分鐘，請耐心等待。',
    'c_result_tips':'訂單號: {text} 錢包充值成功，請等待1-3分鍾創建訂單，感謝支持。',
  },
  reviews:{
    'title':'TraveleSIM 廣受全球超1,000,000人次用戶信賴',
    'add_up':'累計 6,000 多条',
    'reviews':'评论'
  },
  wallet:{
    'balance':'賬戶余額',
    'details':'明細',
    'card_expenses':'支出明細',
    'card_receipts':'匯入明細',
    'card_rules':'充値規則',
    'subtitle':'選擇充值金額',
    'give':'送',
    'wallet_pay':'錢包支付',
    'top_up':'充值',
    'available_balance':'可用余額',
    'insufficient_funds':'餘額不足',
    'transaction_details':'交易明细',
    'all':'全部',
    'expenses':'支出',
    'receipts':'匯入',
    'consent':'同意',
    'services_agreement':'TraveleSIM充值服務協議',
    'tips':'請先閱讀並勾選同意相關協議及政策'
  },
  esimchange:{
    'title':'多個eSIM套餐如何切換使用？',
    'ios_1':'<span>1.</span> <span>進入<b>「設定」</b></span>',
    'ios_2':'<span>2.</span> <span>進入<b>「流動網絡/行動服務」</b></span>',
    'ios_3':'<span>3.</span> <span>選中您想使用的 <b>eSIM</b></span>',
    'ios_4':'<span>4.</span> <span>開啟，並打開<b>「數據漫遊」</b></span>',
    'android_1':'<span>1.</span> <span>進入<b>「設定」</b> - <b>「流動網絡/行動方案」</b></span>',
    'android_2':'<span>2.</span> <span>進入<b>「SIM 卡管理」</b></span>',
    'android_3':'<span>3.</span> <span>切換<b>「預設流動數據」</b>卡</span>',
    'android_4':'<span>4.</span> <span>確認已開啟<b>「數據漫遊」</b></span>',
  },
  footer:{
    'name':'優遊寶國際控股有限公司',
    'hk_name':'香港總部',
    'hk_addess':'香港九龍長沙灣大南西街1018號東方國際大廈11樓1103室',
    'am_name':'澳門分部',
    'am_addess':'澳門宋玉生廣場336-342號誠豐商業中心17樓F/G室',
    'sz_name':'深圳分部',
    'sz_addess':'深圳市南山區科偉路3號東方科技大廈2515室',
    'tw_name':'臺灣分部',
    'tw_addess':'臺中市北屯區平德里永定2街35號1樓',
    'link_title':'友情鏈接',
    'link_yyb':'優遊寶官網',
    'link_wyb':'萬遊寶商城',
    'link_esim':'Travelesim商城',
    'contact_us':'客服咨詢',
    'commerce_contact':'合作咨詢',
    'scan_app':'掃碼下載APP',
    'scan_wechat':'掃碼關註公眾號',
    'download':'下載TraveleSIM APP',
    'rights':'版權所有'
  },
  coupon:{
    'go':'去使用',
    'use':'去使用',
    'check':'查看優惠券',
    'tab_1':'待使用',
    'tab_2':'已使用',
    'tab_3':'已過期',
    'tab_4':'可用優惠券',
    'tab_5':'不可用優惠券',
    'disabled':'未滿使用金額',
    'expired':'優惠券已過期',
    'empty':'暫無優惠券',
    'no_available':'無可用優惠券',
    'tips':'請選擇優惠券',
    'auto':'已為您自動選擇最佳優惠券',
  },
  invite:{
    'mycode':'我的邀請碼',
    'code':'邀請碼',
    'copy':'復製',
    'share':'立即分享',
    'records':'邀請記錄',
    'use':'去使用',
    'no_record':'暫無記錄，快去分享你的邀請碼吧~',
    'know':'我知道了',
    'now':'立即體驗',
    'invited':'共邀請好友',
    'coupons':'共獲得優惠券（張）',
    'rules':'活動規則',
    'text_1':'分享邀請碼，領取優惠券',
    'text_2':'分享邀請碼',
    'text_3':'分享您的邀請碼，推薦好友下載使用TraveleSIM。',
    'text_4':'好友註冊',
    'text_5':'好友使用您的邀請碼完成註冊，即獲$1獎券。（僅活動期間）',
    'text_6':'獲得優惠券',
    'text_7':'好友完成註冊后，您將獲得$1優惠券。',
    'text_8':'分享文案已復製',
    'text_9':'文案已復製，快去分享給你的好友，一起體驗eSIM吧~',
  },
  login: {
    'invite': '使用邀請碼註冊，可獲得優惠券',
    'reset':'密碼不一致，請重新輸入',
    'logout_title':'退出登錄',
    'logout_text':'確認退出登錄？',
    'logout_ok':'退出登錄',
    'title_1':'登錄',
    'title_2':'註冊',
    'title_3':'忘記密碼',
    'title_4':'綁定賬號',
    'tips_0':'請輸入郵箱地址',
    'tips_1':'請輸入手機號碼',
    'tips_2':'請再次確認密碼',
    'tips_3':'請輸入密碼',
    'tips_4':'請輸入驗證碼',
    'tips_5':'獲取驗證碼',
    'tips_6':'已有賬號，',
    'tips_7':'馬上登錄',
    'tips_8':'請輸入新的賬號密碼',
    'tips_9':'請再次確認新的賬號密碼',
    'tips_10':'返回登錄',
    'tips_11':'請輸入邀請碼（可選填）',
    'tips_12':'秒後重新發送',
    'tips_13':'請輸入手機號碼/郵箱地址',
    'tips_14':'請輸入手機區號',
    'tips_15':'請輸入正確郵箱地址',
    'tips_16':'請輸入正確手機號碼',
    'tips_17':'請輸入6-16位數字字母組合密碼',
    'area':'請選擇區號',
    'treaty_1':'《用戶協議》',
    'treaty_2':'《隱私政策》',
    'text_1':'輸入注冊賬號，點擊“登錄”，即代表您同意我們的',
    'text_2':'為了您的賬號安全，請驗證郵箱',
    'text_3':'為了您的賬號安全，請驗證手機',
    'text_4':'輸入郵箱地址，注冊TraveleSIM賬號。點擊“注冊”，即代表您同意我們的',
    'text':'和',
    'tab_1':'或手機',
    'tab_2':'或郵箱',
    'other':'第三方賬號'
  },
  mine:{
    'ios_title':'iOS 教程',
    'android_title':'安卓教程',
    'tab_1':'全部',
    'tab_2':'待付款',
    'tab_3':'待使用',
    'tab_4':'使用中',
    'tab_5':'已過期',
    'tab_6':'剩余付款時間：',
    'user_1':'賬號：',
    'user_2':'登錄密碼',
    'user_3':'設置',
    'user_4':'修改密碼',
    'user_5':'綁定郵箱',
    'user_6':'已綁定',
    'user_7':'未綁定',
    'user_8':'修改',
    'user_9':'綁定',
    'user_10':'綁定手機',
    'user_11':'請輸入舊密碼',
    'user_12':'請輸入新密碼',
    'user_13':'請輸入郵箱地址',
    'user_14':'請輸入手機號碼',
    'user_15':'分享鏈接',
    'user_16':'複製',
    'user_17':'設置密碼',
    'user_18':'分享二維碼',
    "terminate_btn":"註銷",
    "terminate_title":"註銷賬戶",
    "terminate_tips":"是否確認註銷當前賬戶，註銷後賬戶無法找回，請謹慎操作",
    "terminate_cancel":"取消",
    "terminate_sure":"確定",
    'order_1':'地區套餐',
    'order_2':'下單時間',
    'order_3':'實付款',
    'order_4':'訂單狀態',
    'order_5':'支付方式',
    'order_6':'操作',
    'order_7':'訂單編號',
    'order_8':'立即支付',
    'order_9':'實付：',
    'order_10':'暫無訂單',
    'order_11':'優惠券',
    'data':'可用流量',
    'flow_1':'使用中',
    'flow_2':'待激活',
    'flow_3':'復製成功',
    'flow_4':'復製失敗',
    'flow_5':'流量已使用：',
    'flow_6':'激活時間',
    'flow_7':'過期時間',
    'flow_8':'使用eSIM方法一：掃碼QR碼',
    'flow_9':'使用eSIM方法二：手動輸入QR碼信息',
    'flow_10':'激活碼',
    'flow_11':'安裝指南',
    'flow_12':'再次購買',
    'flow_13':'於{msg}過期',
    'flow_14':'暫無套餐',
    'flow_15':'SM-DP+地址',
    'flow_16':'已過期',
    'flow_17':'數據顯示有一定延遲，請以實際使用情況為準。',
    'share_0':'請選擇日期',
    'share_1':'創建時間',
    'share_2':'訂單號',
    'share_3':'請輸入訂單號',
    'share_4':'狀態',
    'share_5':'請選擇狀態',
    'share_6':'已支付',
    'share_7':'已退款',
    'share_8':'導出',
    'share_9':'重置',
    'share_10':'搜索',
    'share_11':'賬號',
    'share_12':'數量',
    'share_13':'付款金額',
    'share_14':'傭金',
    'share_15':'類型',
    'share_16':'來源',
    'share_17':'傭金比例',
  },
  http: {
    'timeout':'服務器響應超時，請刷新當前頁',
    'fail': '數據獲取失敗',
    'server_fail':'連接服務器失敗',
    'err': '請求接口失敗',
    '400': '請求錯誤',
    '404': '請求錯誤,未找到該資源',
    '500':'服務器端出錯',
    '502':'網絡錯誤',
    '504':'網絡超時',
  },
  user:{
    'top_up_title':'TraveleSIM充值服務協議',
    'top_up_rules':`
    <p>
    TraveleSIM（以下簡稱“本平臺”）按照《充值服務協議》（以下簡稱“本協議”）的規定提供基於互聯網的充值服務，為獲得充值服務，服務使用人（以下稱“用戶”或“您”）同意本協議的全部條款並按照頁面上的提示完成全部的充值流程。
    </p>
    <p>
    用戶在使用本平臺提供的各項服務之前，應仔細閱讀本服務協議，特別是免除或者限制責任的條款。<span>如用戶不同意本協議及/或對其的修改，請停止使用本平臺提供的充值服務，</span>用戶在進行充值的過程中使用充值服務即表示用戶完全接受本協議項下的全部條款。
    </p>
    <p>
      <span>特別提示：本平臺堅決反對未成年人在未經監護人同意的情況下使用充值服務，未成年人請委託監護人操作或在監護人明示同意下操作，否則請勿使用本充值服務。</span>
    </p>
    <p class="subtitle">
    1.服務說明
    </p>
    <p>
    1.1.用戶可通過本平臺充值服務進行充值，用以購買消費<b>本平臺</b>相關產品或服務。
    </p>
    <p>
    1.2.用戶充值成功後，可於“<b>個人中心-我的錢包</b>”中查看账户餘額。
    </p>
    <p>
    1.3.用戶在購買產品時，可於訂單支付頁面選擇使用錢包餘額進行支付，具體交易明細請進入“<b>個人中心-我的錢包-支出明細/匯入明細</b>”中查看。
    </p>
    <p>
    1.4.用戶如需退款，需在充值成功後的<b>180天內（6個月）</b>與本平臺客服聯繫申請。<span>充值金額的未使用部分可以申請退款，可退款金額的25%將作為手續費扣除。</span>扣除手續費後剩餘的金額將在申請後3個工作日內全部退還。
    </p>
    <p class="subtitle">
    2.服務使用規則（重要條款）
    </p>
    <p>
    2.1.充值真實準確性
    </p>
    <ul>
      <li>
      2.1.1.您可以在充值服務頁面上自由選擇具體的充值方式，並按頁面提示的流程完成充值。<span>請您務必在充值前，仔細核對帳戶ID、支付方式、支付金額等具體資訊。</span>
      </li>
      <li>
      2.1.2.您在選擇充值金額，使用具體充值方式進行充值成功後，將會收到對應的可用金額（即賬戶餘額）。充值成功後請保留充值訂單號以作為日後發生問題时的核對依據憑證（用戶就充值服務投訴，但未能提供有效憑證作為佐證時，本平臺將不會作出補償或賠償）。
      </li>
      <li>
      若因為用戶自身輸入帳號錯誤、操作不當或不按照充值流程操作等因素造成選錯支付方式、充錯金額、發生充值錯誤、充錯帳號等情形而損害自身權益的，<span>本平臺將不會作補償或賠償。</span>
      </li>
    </ul>
    <p>
    2.2.充值合法正當性
    </p>
    <ul>
      <li>
      2.2.1.用戶必須遵循本協議規則及法律法規，嚴格按照有關監管部門規定使用本平臺提供的充值服務，如用戶在充值時使用第三方支付機構提供的服務，還應當遵守該第三方機構的各項協議及其服務規則。
      </li>
      <li>
      2.2.2.您應保證並承諾，<span>在本平臺充值使用的資金來源為您的合法財產，且充值行為不存在任何違法犯罪目的。</span>如您充值所用資金被國家司法機關、執法機關認定為非法並導致相關司法機關、執法機關對本平臺銀行帳戶採取凍結、劃扣在內等強制措施，所造成的一切損失由您負責向本平臺賠償，包括但不限於本平臺因主張權利而支出的公證費、訴訟費、保全費、律師費以及被強制劃扣的全部費用。
      </li>
      <li>
      2.2.3.請您知悉，若您以非法的方式，或使用非本平臺所指定的充值方式進行充值，本平臺不保證該充值順利或者正確完成。因此造成用戶權益受損的，本平臺不會作補償或賠償；同時本平臺保留隨時凍結、註銷該用戶帳號以及追究平臺所遭受損失的權利。
      </li>
      <li>
      2.2.4.用戶不得通過本平臺充值進行任何違法犯罪活動（如洗錢、套現等），不得利用充值漏洞謀利，否則本平臺有權終止服務，追繳非法獲利；情節嚴重的，依法移交司法機關。
      </li>
    </ul>
    <p>
    2.3.充值安全規範
    </p>
    <ul>
      <li>
      2.3.1.用戶請知悉，在使用充值服務過程中您應妥善保管自身個人資訊，包括但不限於銀行帳號、密碼、驗證碼等。
      </li>
      <li>
      2.3.2.本平臺禁止用戶盜用、冒用他人平臺帳號進行充值或使用各服務功能，如經發現，本平臺將暫停或終止該帳戶部分或全部功能和服務，且不退還或支付任何款項。
      </li>
    </ul>
    <p class="subtitle">
    3.協議變更及不可抗力
    </p>
    <p>
    3.1.您理解並認可，本平臺可根據國家法律法規之變化或本平臺服務變化的需要而更新或修改本協議，並通過合理的方式向您送達修改通知，包括但不限於系統通知、彈窗消息推送等。如您對變更事項不同意，您應當於變更事項確定的生效之日起停止使用本平臺服務；如您在變更事項生效後仍繼續使用本平臺服務，則視為您同意已生效的變更事項。
    </p>
    <p>
    3.2.<span>由不可抗力及不可預見的情勢導致的各種情況和糾紛，本平臺將盡可能提前通知您，您理解並同意本平臺對此無需承擔責任。</span>不可抗力和不可預見情勢包括但不限於：駭客攻擊、電信部門技術調整導致的重大影響、政府管制導致的暫時關閉、病毒侵襲。
    </p>
    <p>
    <span>在此，您再次保證已經完全閱讀並充分理解了本協議條款，接受上述所有條款的約束。</span>
    </p>
    `,
    'privacy_title':'TraveleSIM隱私政策',
    'privacy':`
    <p>TraveleSIM遵守適用的數據保護及隱私法律和規例（“適用法律”）。在適用法律允許的最大範圍內，及受用戶在適用法律要求下的有效同意所規限，用戶在使用TraveleSIM軟件、服務、網站或任何其他提供TraveleSIM服務的第三方平臺，即表示同意及接受本隱私政策。我們可能不時更新本隱私政策以反映我們的軟件或商品的改變和用戶意見，或適用法律的改變。如本隱私政策或對於我們將如何使用用戶的個人資料之方式有重大改變時，我們會在實施該等改變之前以明顯的方式刊登有關通知或直接向用戶發出通知。我們鼓勵用戶定期細閱讀本隱私政策，以得知我們正如何收集，使用及保護用戶的資料。</p>
    <p class="subtitle">1. 我們收集的個人信息</p>
    <p>我們可能會不時向用戶收集個人資料或其他信息，以便向用戶提供我們的產品或服務。我們所收集的信息包括但不限於： </p>
    <p>(a)用戶在註冊時所提供的資料，或是稍後顯示在TraveleSIM軟件用戶端、TraveleSIM線上搜尋目錄以及在TraveleSIM軟件的個人中心頁面的TraveleSIM個人資料。其中可能包括用戶的TraveleSIM手機號碼等，以及用戶同意提供的所有其他資料； </p>
    <p>(b)身份識別資料（如手機號碼、郵箱）； </p>
    <p>(c)電子識別資料（如IP地址、cookies）； </p>
    <p>(d)有關用戶使用我們的軟件、服務、產品或網頁互動的資訊（包括電腦、用戶端資訊、錯誤報告、頁面瀏覽統計資料、瀏覽器類型和使用統計資料)；  </p>
    <p>(e)流量數據（為傳送通訊或計費而處理的數據）； </p>
    <p>(f)付款詳情，包括收件人信息及支付方式； </p>
    <p>(g)聯系人詳情，包括聯系人姓名、電話號碼等。 </p>
    <p>此外，用戶的線上時段資料可能會間接地以統計和匿名的方式被收集。 </p>
    <p>如用戶未滿18歲時，用戶需在家長或監護人的同意和監督下提供個人資料。 </p>
    <p>為實現【第三方平臺登錄】的功能，我們使用了Google 的產品OAuth。參照如下：</p>
    <p>Google 的 API 服務條款：<a href="https://developers.google.com/terms" target="_blank">https://developers.google.com/terms</a></p>
    <p>Google API 服務用戶數據政策：<a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">https://developers.google.com/terms/api-services-user-data-policy</a></p>
    <p class="subtitle">2.用戶個人信息的使用</p>
    <p>我們收集信息的主要目的是為用戶提供安全、順暢、有效率的體驗。受用戶的有效同意所規限，我們於取得許可或必要的情況下會收集和使用、或委托第三方服務供應者代表我們收集和使用有關用戶的個人資料，如 ： </p>
    <p>(a)接受、處理或傳送經由我們的網站產生的訂單，開立發票給用戶或通知用戶訂單狀態； </p>
    <p>(b)為用戶提供其他服務（如我們收集資訊時所述）；</p>
    <p>(c)為用戶提供客戶緊急救援及其它疑難排解服務；</p>
    <p>(d)比較資訊的準確性；</p>
    <p>(e)通知用戶關於服務更新與故障的資訊；</p>
    <p>(f)解決爭議；</p>
    <p>(g)驗證用戶的身份；</p>
    <p>(h)改善我們的軟體、服務或網站； </p>
    <p>(i)籌劃與執行我們的營銷或推廣活動、優惠和類似活動；</p>
    <p>(j)通過廣告支持，免費提供某些軟件、功能及網站；</p>
    <p>(k)針對故障和服務問題與用戶聯絡，傳送相關訊息；</p>
    <p>(l)以電子郵件、軟件或短信進行通知，使用戶知悉關於使用TraveleSIM軟件、產品或網站的最終求償資訊，包括但不限於侵犯第三方權利的侵權求償； </p>
    <p>(m)使用短信或TraveleSIM消息去通知用戶TraveleSIM軟件、服務或網站的最新信息，及告知用戶我們的相關推廣。</p>
    <p class="subtitle">3.用戶個人信息的披露及轉移</p>
    <p>除了以下所述之外，在未獲得用戶的有效及明確許可下，TraveleSIM不會銷售、出租、交易或轉讓任何個人流量數據或通訊內容，除非TraveleSIM在適用法律或管轄機構的命令下有義務作出上述行為。 </p>
    <p>    TraveleSIM可能披露個人信息以回應法律規定，行使我們的法律權利或於索償中抗辯，以保護TraveleSIM的利益，打擊詐欺行為及執行我們的政策或保護用戶的合法權利、財產或安全。 </p>
    <p>    TraveleSIM、TraveleSIM的合作夥伴或促進用戶的通訊運營商或公司可提供個人資料、通訊內容或流量資料給適當司法、執法或合法地要求該等信息的政府機構。受用戶的有效及明確同意所限，TraveleSIM可能將用戶的個人資料提供給供應商或TraveleSIM的關聯公司。</p>
    <p class="subtitle">4. 用戶的個人信息安全及保存</p>
    <p>我們會在用戶是我們客戶期間或用戶已不再使用我們的服務之後儲存用戶的個人信息，但此僅為有必要的或者因應法律要求。我們將嚴格執行相關法律下的適用義務和例外規定，采取適當的組織性和技術性措施保護向其提供或所收集的個人資料和流量資料。用戶的個人資料和流量資料僅供經許可員工或第三方存取，並且只會在必要時才保存至達到履行其收集的原來目的或直接相關的目的時，個人資料和流量資料被保存以符合任何適用法律或合同義務除外。</p>
    <p class="subtitle">5.查閱或改正數據</p>
    <p>用戶有權查閱用戶的個人信息，並在相關的情況下加以改正。 </p>
    <p>除非法律要求保留或因為合法的商業目的，我們將盡合理努力滿足刪除個人資訊的要求。 </p>
    <p>    根據現行法律，我們保留對處理任何查閱資料請求而收取合理費用的權利。</p>
    <p class="subtitle">6.手機通知服務</p>
    <p>當用戶的手機使用TraveleSIM時，我們可能會在用戶沒有執行或沒有使用TraveleSIM應用的情況下，利用該手機的通知服務讓用戶得知資訊。這些服務項目可能由第三方提供。例如，傳送到Apple裝置的訊息會使用Apple的緊急通知服務。 </p>
    <p>    通知服務可能收到關於來電者、訊息發送者和訊息內容的資訊以提供服務，並可能根據服務提供者的條款和細則及隱私權原則來使用這些資訊。 </p>
    <p>(Apple為AppleInc.在美國及其他國家的註冊商標。)</p>
    <p class="subtitle">7.位置數據的用途</p>
    <p>我們收集的位置數據將用於以下目的：</p>
    <p>(a)地區套餐激活與推薦：根據您的當前位置，我們為您提供與當前地區相匹配的 eSIM 套餐和網絡服務。</p>
    <p>(b)網絡優化與服務提供：根據您的位置優化 eSIM 網絡連接，確保在您所在地區提供最佳的服務體驗。</p>
    <p>(c)跨區域支持：如果您在旅行過程中跨越不同地區，我們將使用位置數據幫助自動切換到適合該地區的套餐或網絡服務，確保您能夠持續使用 eSIM 服務。</p>
    <p class="subtitle">8.聯系我們</p>
    <p>如用戶對本隱私政策有任何查詢，請在TraveleSIM應用內聯系我們客服。</p>`, 
    'treaty_title':'用戶註冊協議',
    'treaty':`
    <P>TraveleSIM提醒您（用戶）在註冊成為用戶之前，請認真閱讀本《用戶協議》（以下簡稱“協議”），確保您充分理解本協議中各條款。請您完整閱讀並選擇是否接受本協議。除非您接受本協議所有條款，否則您無權註冊、登錄或使用本協議所涉服務。您的註冊、登錄、使用等行為將視為對本協議的接受，並同意接受本協議各項條款的約束。</P>
    <P>本協議約定TraveleSIM與用戶之間關於TraveleSIM軟件服務（以下簡稱“服務”）的權利義務。“用戶”是指註冊、登錄、使用本服務的個人。</P>
    <P class="subtitle">一、 賬號註冊</P>
    <P>1、 用戶在使用本服務前需要註冊一個TraveleSIM賬號。TraveleSIM賬號應當使用手機號碼/郵箱註冊，請用戶使用尚未註冊的手機號碼或郵箱賬號註冊。TraveleSIM可以根據用戶需求或產品需要對賬號註冊和綁定的方式進行變更，無須事先通知用戶。</P>
    <P>2、 TraveleSIM系基於地理位置的移動互聯網產品，用戶註冊時應當授權TraveleSIM公開及使用其方可成功註冊TraveleSIM賬號。故用戶完成註冊即表明用戶同意TraveleSIM提取、公開信息。</P>
    <P>3、 鑒於TraveleSIM賬號的綁定註冊方式，TraveleSIM在用戶註冊時將自動提取用戶手機號碼及手機設備識別碼等信息。</P>
    <P>4、 在用戶註冊及使用本服務時，TraveleSIM需要搜集能識別用戶身份的個人信息以便TraveleSIM可以在必要時聯系用戶，或為用戶提供更好的使用體驗。</P>
    <P class="subtitle">二、 服務內容</P>
    <P>本服務的具體內容由TraveleSIM根據實際情況提供，TraveleSIM可以對其提供的服務予以變更，服務變更將在更新版本時一並提供。</P>
    <P class="subtitle">三、 用戶個人隱私信息保護</P>
    <P>1、 用戶在註冊賬號或使用本服務的過程中，可能需要填寫或提交一些必要的信息，如法律法規、規章規範性文件（以下稱“法律法規”）規定的需要填寫的身份信息。如用戶提交的信息不完整或不符合法律法規的規定，則用戶可能無法使用本服務或在使用本服務的過程中受到限製。</P>
    <P>2、 個人隱私信息是指涉及用戶個人身份或個人隱私的信息，比如，用戶真實姓名、手機號碼、手機設備識別碼、IP地址。非個人隱私信息是指用戶對本服務的操作狀態以及使用習慣等明確且客觀反映在TraveleSIM服務器端的基本記錄信息、個人隱私信息範圍外的其他普通信息，以及用戶同意公開的上述隱私信息。</P>
    <P>3、 TraveleSIM尊重用戶個人隱私信息的私有性，確保個人隱私信息安全，防止在本服務中收集的用戶個人隱私信息泄露、毀損或丟失。在發生前述情形或者TraveleSIM發現存在發生前述情形的可能時，將及時采取補救措施。</P>
    <P>4、 TraveleSIM未經用戶同意不向任何第三方公開、透露用戶個人隱私信息，但以下特定情形除外：</P>
    <P>（1）TraveleSIM根據法律法規規定或有權機關的指示提供用戶的個人隱私信息；</P>
    <P>（2）由於用戶與他人共享註冊賬戶，由此導致的任何個人信息的泄露，或其他非TraveleSIM原因導致的個人隱私信息的泄露；</P>
    <P>（3）用戶自行向第三方公開其個人隱私信息；</P>
    <P>（4）用戶與TraveleSIM及合作單位之間就用戶個人隱私信息的使用公開達成約定，TraveleSIM因此向合作單位公開用戶隱私信息；</P>
    <P>（5）為防止冒用他人的身份進行註冊，本軟件將通過短信方式來驗證手機號碼，註冊或邀請所產生的短信費用將由運營商收取；</P>
    <P>（6）任何由於黑客攻擊、電腦病毒侵入及其他不可抗力事件導致用戶個人隱私信息的泄露。</P>
    <P>5、用戶同意TraveleSIM可在以下事項中使用用戶的個人信息：</P>
    <P>（1）TraveleSIM向用戶及時發送重要通知，如軟件更新、本協議條款的變更；</P>
    <P>（2）TraveleSIM由內部進行審計、數據分析和研究等，以改進TraveleSIM的產品、服務和用戶之間的溝通；</P>
    <P>（3）依本協議約定，TraveleSIM管理、審查用戶信息及進行處理措施；</P>
    <P>（4）適用法律法規規定的其他事項。</P>
    <P>除上述事項外，如未取得用戶事先同意，TraveleSIM不會將用戶個人隱私信息適用於任何其他用途。</P>
    <P>6、TraveleSIM重視對未成年人個人隱私信息的保護。TraveleSIM將依賴用戶提供的個人信息判斷用戶是否為未成年人。任何18歲以下的未成年人註冊賬號或使用本服務應事先取得家長或其法定監護人（以下簡稱“監護人”）的同意。除根據法律法規的規定及有權機關的指示披露外，TraveleSIM不會使用或向任何第三方透露未成年人的個人隱私信息。</P>
    <P>7、用戶確認：地理位置信息為非個人隱私信息，用戶成功註冊TraveleSIM賬號視為確認授權TraveleSIM自由提取。</P>
    <P>8、為了改善TraveleSIM的技術和服務，向用戶提供更好地服務體驗，TraveleSIM會自行收集使用或向第三方提供用戶的非個人隱私信息。</P>
    <P class="subtitle">四、 內容規範</P>
    <P>1、 本條所述內容是指用戶使用本服務過程中所製作、上傳、復製、發布、傳播的任何內容，包括但不限於賬號頭像、名稱等註冊信息及認證資料，以及其他使用賬號或本服務所產生的內容。</P>
    <P>2、 用戶不得利用TraveleSIM賬號或本服務製作、上傳、復製、發布、傳播如下法律、法規和政策禁止的內容：</P>
    <P>（1）反對憲法所確定的基本原則的；</P>
    <P>（2）危害國家安全，泄露國家秘密，顛覆國家政權，破壞國家統一的；</P>
    <P>（3）損害國家榮譽和利益的；</P>
    <P>（4）煽動民族仇恨、民族歧視、破壞民族團結的；</P>
    <P>（5）破壞國家宗教政策，宣揚邪教和封建迷信的；</P>
    <P>（6）散布謠言，擾亂社會秩序，破壞社會穩定的；</P>
    <P>（7）散布淫穢、色情、賭博、暴力、兇殺、恐怖或者教唆犯罪的；</P>
    <P>（8）侮辱或者誹謗他人，侵害他人合法權益的；</P>
    <P>（9）含有法律、行政法規禁止的其他內容的信息。</P>
    <P>3、 用戶不得利用TraveleSIM賬號或本服務製作、上傳、復製、發布、傳播如下幹擾TraveleSIM正常運營，以及侵犯其他用戶或第三方合法權益的內容：</P>
    <P>（1）含有任何性或性暗示的；</P>
    <P>（2）含有辱罵、恐嚇、威脅內容的；</P>
    <P>（3）含有騷擾、垃圾廣告、惡意信息、誘騙信息的；</P>
    <P>（4）涉及他人隱私、個人信息或資料的；</P>
    <P>（5）侵害他人名譽權、肖像權、知識產權、商業秘密等合法權利的；</P>
    <P>（6）含有其他幹擾本服務正常運營和侵犯其他用戶或第三方合法權內容的信息。</P>
    <P class="subtitle">五、 使用規則</P>
    <P>1、 用戶在本服務中或通過本服務所傳送、發布的任何內容並不反映或代表，也不得被視為反映或代表TraveleSIM的觀點、立場或政策，TraveleSIM對此不承擔任何責任。</P>
    <P>2、 用戶不得利用TraveleSIM賬號或本服務進行如下行為：</P>
    <P>（1）提交、發布虛假信息，或盜用他人頭像或資料，冒充、利用他人名義的；</P>
    <P>（2）強製、誘導其他用戶關註、點擊鏈接頁面或分享信息的；</P>
    <P>（3）虛構事實、隱瞞真相以誤導、欺騙他人的；</P>
    <P>（4）利用技術手段批量建立虛假賬號的；</P>
    <P>（5）利用TraveleSIM賬號或本服務從事任何違法犯罪活動的；</P>
    <P>（6）製作、發布與以上行為相關的方法、工具，或對此類方法、工具進行運營或傳播，無論這些行為是否具有商業目的；</P>
    <P>（7）其他違反法律法規規定、侵犯其他用戶合法權益、幹擾TraveleSIM正常運營或TraveleSIM未明示授權的行為。</P>
    <P>3、 用戶須對利用TraveleSIM賬號或本服務傳送信息的真實性、合法性、無害性、準確性、有效性等安全負責，與用戶所傳播的信息相關的任何法律責任由用戶自行承擔，與TraveleSIM無關。如因此給TraveleSIM或第三方造成損害的，用戶應當依法予以賠償。</P>
    <P>4、 TraveleSIM提供的服務中可能包括廣告、用戶在使用過程中顯示TraveleSIM和第三方供應商、合作夥伴提供的廣告。除法律法規明確規定外，用戶應自行對該廣告信息進行的交易負責，對用戶因該廣告信息進行的交易或前述廣告商提供的內容而遭受的損失或損害，TraveleSIM不承擔任何責任。</P>
    <P class="subtitle">六、 賬號管理</P>
    <P>1、TraveleSIM賬號的所有權歸TraveleSIM所有，用戶完成申請註冊手續後，獲得TraveleSIM賬號的使用權，該使用權僅屬於初始申請註冊人，禁止贈與、借用、租用、轉讓或售賣。TraveleSIM因經營需要，有權回收用戶的賬號。</P>
    <P>2、用戶可以更改、刪除TraveleSIM賬戶上的個人資料、註冊信息及傳送內容等，但需註意，刪除有關信息的同時也會刪除用戶儲存在系統中的文字和圖片。用戶需承擔該風險。</P>
    <P>3、用戶有責任妥善保管註冊賬號信息的安全，因用戶保管不善可能導致遭受盜號或密碼失竊，責任由用戶自行承擔。用戶需要對註冊賬號行為承擔法律責任。用戶同意在任何情況下不使用其他用戶的賬號。在用戶懷疑他人使用其賬號時，用戶同意立即通知TraveleSIM。</P>
    <P>4、用戶應遵守本協議的各項條款，正確、適當地使用本服務，如因用戶違反本協議中的任何條款，TraveleSIM有權依據協議中斷或終止對違約用戶的TraveleSIM賬號提供服務。同時，TraveleSIM保留在任何時候收回賬號的權利。</P>
    <P>5、如用戶註冊賬號後一年不登錄，TraveleSIM可以收回該賬號，以免造成資源浪費，由此造成的不利後果由用戶自行承擔。</P>
    <P class="subtitle">七、 數據儲存</P>
    <P>1、TraveleSIM不虛構、不自行上傳內容，所有數據均由用戶主動上傳。TraveleSIM僅承擔存儲者的角色。</P>
    <P>2、TraveleSIM不對用戶在本服務中相關數據的刪除或儲存失敗負責。</P>
    <P>3、TraveleSIM可以根據實際情況自行決定用戶在本服務中數據的最長儲存期限，並在服務器上為其分配數據最大存儲空間等。用戶可根據自己的需要自行備份本服務中的相關數據。</P>
    <P>4、如用戶停止使用本服務或本服務終止，TraveleSIM可以從服務器上永久地刪除用戶的數據。本服務停止、終止後，TraveleSIM沒有義務向用戶返還任何數據。</P>
    <P class="subtitle">八、 用戶註意事項</P>
    <P>1、用戶違反本協議或相關的服務條款的規定，導致或產生的任何第三方主張的任何索賠、要求或損失，包括合理的律師費，用戶同意賠償本公司與合作公司、關聯公司，並使之免受損害。</P>
    <P>2、本軟件涉及到WiFi聯網和Internet服務，可能會受到各個環節不穩定因素的影響。因此服務存在因上述不可抗力、病毒或黑客攻擊、系統不穩定、用戶所在位置、用戶關機以及其他任何技術、GSM網絡、互聯網絡、通信線路原因等造成服務中斷或不能滿足用戶要求的風險。開通服務的用戶須承擔以上風險，服務方對服務的及時性、安全性、準確性不做擔保，對因此導致的用戶通信不暢，不承擔任何責任。</P>
    <P>3、對於因不可抗力而造成的服務器的死機、網絡的中斷，以及軟件升級時的服務暫停等，進而對本服務用戶造成的損失，服務方不承擔任何責任。</P>
    <P>4、TraveleSIM提供斷網情況下的自動連接，用戶註冊使用，則視為已同意接受此服務，由此產生的數據流量費用由用戶自行承擔。</P>
    <P>5、用戶應提供和分享自有WiFi熱點信息，或保證分享的其他熱點（包括但不限於密碼和地理位置等）在熱點提供者的許可範圍內，並保證分享的所有WiFi熱點的信息安全。由於違反本條而引發的問題，TraveleSIM不承擔任何責任。</P>
    <P>6、用戶應當確保將由TraveleSIM獲取的熱點信息用於恰當的用途，嚴禁任何非法或違背相關準則規定的行為。由此引發的問題，與TraveleSIM無關。</P>
    <P>7、熱點提供者有權利要求從TraveleSIM的數據庫中剔除由其提供的熱點信息。TraveleSIM將按照法律規定予以相應處理。</P>
    <P class="subtitle">九、 法律及爭議解決 </P>
    <P>1、 本協議適用中華人民共和國法律。</P>
    <P>2、 因本協議引起的或與本協議有關的任何爭議，各方應友好協商解決；協商不成則任何一方均可將有關爭議提交至深圳仲裁委員會並按照其屆時有效的仲裁規則仲裁；仲裁裁決是終局的，對各方均有約束力。</P>
    <P class="subtitle">十、 其他條款</P>
    <P>1、 如果本協議中的任何條款無論因何種原因完全或部分無效或不具有執行力，或違反任何適用的法律，則該條款被視為刪除，但本協議的其余條款仍應有效並且有約束力。</P>
    <P>2、 TraveleSIM有權隨時根據有關法律、法規的變化以及公司經營狀況和經營策略的調整等修改本協議。修改後的協議會隨附於新版本軟件發布。當發生有關爭議時，以最新的協議文本為準。如果不同意改動的內容，用戶可以自行刪除本軟件。如果用戶繼續使用本軟件，則視為您接受本協議的變動。</P>
    <P>3、 TraveleSIM在法律允許的最大範圍內對本協議擁有解釋權與修改權。</P>`
  }
}