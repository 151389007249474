import {request,bapi_request} from '../utils/request'
export function GYGEnquiryIccidAPI(parameter) {
  return bapi_request({
    url: 'feedback/iccid',
    method: 'get',
    params: parameter
  })
}
export function GYGEnquiryOrderAPI(parameter) {
  return bapi_request({
    url: 'feedback/order',
    method: 'get',
    params: parameter
  })
}
export function EsimQRCodeAPI(parameter) {
  return bapi_request({
    url: 'query/simplan',
    method: 'get',
    params: parameter
  })
}
export function APPDownloadAPI(parameter) {
  return request({
    url: 'v10/Ucenter/latestVersion',// 获取app下载地址
    method: 'get',
    params: parameter
  })
}
export function GetConfigAPI(parameter) {
  return request({
    url: 'common/config',// 获取配置
    method: 'get',
    params: parameter
  })
}
export function GetHomeDataAPI(parameter) {
  return request({
    url: 'v10/index/h5Index',//首页
    method: 'get',
    params: parameter
  })
}
export function GetNewUserCouponAPI(parameter) {
  return request({
    url: 'v102/coupon/getNewCoupon',// 获取新人优惠券
    method: 'get',
    params: parameter
  })
}
export function GetActInfoAPI(parameter) {
  return request({
    url: 'v102/act/getActInfo',// 获取活动信息
    method: 'get',
    params: parameter
  })
}
export function GetGuideListAPI(parameter) {
  return request({
    url: 'v10/Ucenter/userGuideList',// 获取使用帮助列表
    method: 'get',
    params: parameter
  })
}
export function SearchProductAPI(parameter) {
  return request({
    url: 'v10/product/search',// 搜索商品
    method: 'get',
    params: parameter
  })
}
export function ProductInfoAPI(parameter) {
  return request({
    url: 'v10/product/info',// 获取商品详情
    method: 'get',
    params: parameter
  })
}
export function OtherProductAPI(parameter) {
  return request({
    url: 'v10/product/otherProduct',// 获取其它套餐
    method: 'get',
    params: parameter
  })
}
export function GetRechargeListAPI(parameter) {
  return request({
    url: 'v103/recharge/index',// 获取充值列表
    method: 'get',
    params: parameter
  })
}
export function CreateRechargeOrderAPI(parameter) {
  return request({
    url: 'v103/recharge/recharge',// 创建充值订单
    method: 'post',
    data: parameter
  })
}
export function GetPaymentsAPI(parameter) {
  return request({
    url: 'v101/order/confirm',// 获取支付方式
    method: 'get',
    params: parameter
  })
}
export function GetOrderCouponsAPI(parameter) {
  return request({
    url: 'v102/coupon/getActiveCouponList',// 获取订单优惠券
    method: 'get',
    params: parameter
  })
}
export function CreateOrderAPI(parameter) {
  return request({
    url: 'v101/order/createOrder',// 创建订单
    method: 'post',
    data: parameter
  })
}
export function OrderStatusAPI(parameter) {
  return request({
    url: 'v10/order/status',// 获取订单状态
    method: 'get',
    params: parameter
  })
}
export function RechargeOrderStatusAPI(parameter) {
  return request({
    url: 'v103/recharge/status',// 获取充值订单状态
    method: 'get',
    params: parameter
  })
}
export function OrderListAPI(parameter) {
  return request({
    url: 'v101/Order/orderList',// 获取订单列表
    method: 'post',
    data: parameter
  })
}
export function WalletOrderListAPI(parameter) {
  return request({
    url: 'v103/spread/commission',// 获取订单列表
    method: 'get',
    params: parameter
  })
}
export function UserProductAPI(parameter) {
  return request({
    url: 'v10/dataplan/validList',// 获取我的套餐
    method: 'get',
    params: parameter
  })
}
export function UserExpiredProductAPI(parameter) {
  return request({
    url: 'v10/dataplan/expiredList',// 获取过期套餐
    method: 'get',
    params: parameter
  })
}
export function UserInviteInfoAPI(parameter) {
  return request({
    url: 'v102/user/getInviteInfo',// 获取邀请信息
    method: 'get',
    params: parameter
  })
}
export function UserCouponListAPI(parameter) {
  return request({
    url: 'v102/coupon/userCouponList',// 获取我的优惠券
    method: 'get',
    params: parameter
  })
}
export function UserShareListAPI(parameter) {
  return request({
    url: 'v101/Ucenter/shareOrderList',// 获取我的优惠券
    method: 'get',
    params: parameter
  })
}
export function UserInfoAPI(parameter) {
  return request({
    url: 'v101/ucenter/userinfo',// 获取用户信息
    method: 'get',
    params: parameter
  })
}
export function SendCodeAPI(parameter) {
  return request({
    url: 'common/sendCode',// 获取验证码
    method: 'post',
    data: parameter
  })
}
export function UserThirdLoginAPI(parameter) {
  return request({
    url: 'v101/user/thirdLogin',// 第三方登录
    method: 'post',
    data: parameter
  })
}
export function UserThirdBindAPI(parameter) {
  return request({
    url: 'v101/user/thirdBind',// 第三方登录绑定账号
    method: 'post',
    data: parameter
  })
}
export function UserUnsubscribeApi() {
  return request({
    url: "v101/user/deleteUser", // 注销
    method: "get",
  });
}
export function UserLoginAPI(parameter) {
  return request({
    url: 'v101/User/passwordLogin',// 登录
    method: 'post',
    data: parameter
  })
}
export function UserLogoutAPI(parameter) {
  return request({
    url: 'v10/user/logout',// 退出登录
    method: 'post',
    data: parameter
  })
}
export function UserRegisterAPI(parameter) {
  return request({
    url: 'v101/User/register',// 注册
    method: 'post',
    data: parameter
  })
}
export function UserResetPwdAPI(parameter) {
  return request({
    url: 'v101/User/resetPwd',//忘记密码
    method: 'post',
    data: parameter
  })
}
export function UserSetPwdAPI(parameter) {
  return request({
    url: 'v101/User/setPwd',// 设置密码
    method: 'post',
    data: parameter
  })
}
export function UserChangePwdAPI(parameter) {
  return request({
    url: 'v101/User/changePwd',// 修改密码
    method: 'post',
    data: parameter
  })
}
export function UserBindMobileAPI(parameter) {
  return request({
    url: 'v101/User/bindMobile',// 绑定手机
    method: 'post',
    data: parameter
  })
}
export function UserBindEmailAPI(parameter) {
  return request({
    url: 'v101/User/bindEmail',// 绑定邮箱
    method: 'post',
    data: parameter
  })
}