<template>
  <div id="download">
    <div class="bg flex-center-column">
      <div class="header">
        <img class="logo" src="../assets/download_logo.png" alt="" srcset="" />
        <div class="name">TraveleSIM</div>
        <div class="slogan-en">Global Connection · Anytime · Anywhere</div>
        <div class="slogan-cn">环旅上网，即刻连接</div>
      </div>
      <div class="bg-img">
        <img src="../assets/download_img.png" alt="" srcset="" />
      </div>
      <img
        class="btn-img"
        @click="download"
        src="../assets/download_btn.png"
        alt=""
        srcset=""
      />
    </div>
    <a-modal
      :visible="visible"
      :footer="null"
      :title="$t('footer.download')"
      :destroyOnClose="true"
      @cancel="visible = false"
      wrapClassName="download-modal"
    >
      <a-row>
        <a-col :xs="24" :sm="24" :md="12" class="flex">
          <vue-qr :text="ios.url" :size="230" :margin="20" :logoSrc="ios.icon" :logoScale="0.2"></vue-qr>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" class="flex">
          <vue-qr :text="Android.url" :size="230" :margin="20" :logoSrc="Android.icon" :logoScale="0.2"></vue-qr>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { Toast } from "vant";
import VueQr from "vue-qr";
import { APPDownloadAPI } from '../api/api'
export default {
  components: { VueQr },
  data() {
    return {
      visible:false,
      userAgent: "",
      Android: {
        url: "",
        icon: require("../assets/2.png"),
      },
      ios: {
        url: "https://apps.apple.com/app/id1542583092",
        icon: require("../assets/1.png"),
      },
    };
  },
  mounted() {
    console.log(navigator.userAgent);
    if (
      navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      "micromessenger"&&navigator.userAgent.indexOf("Android") > -1
    ) {
      this.userAgent = "Wechant";
    } else if (
      navigator.userAgent.indexOf("iOS") > -1 ||
      navigator.userAgent.indexOf("Mac") > -1
    ) {
      this.userAgent = "iOS";
    } else if (navigator.userAgent.indexOf("Android") > -1) {
      this.userAgent = "Android";
    } else if (navigator.userAgent.indexOf("Windows") > -1) {
      this.userAgent = "Windows";
    }
    console.log(this.userAgent);
  },
  methods: {
    async download() {
      const { data:{url} } = await APPDownloadAPI()
      this.Android.url = url;
      switch (this.userAgent) {
        case "Wechant":
          Toast(this.$t('common.download_tips'));
          break;
        case "Android":
          window.location.href = this.Android.url;
          break;
        case "iOS":
          window.location.href = this.ios.url;
          break;
        case "Windows":
          this.visible = true;
          this.Android.url = "https://esim.qn.triproaming.cn/app/TraveleSIM_v2.0.0_release.apk";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#download {
  user-select: none;
  color: $color;
  .download-qrcode {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .block {
    width: 650px;
    .download-qrcode {
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .name {
      font-size: 32px;
      color: $color;
    }
  }
  .bg {
    width: 100%;
    min-height: 80vh;
    padding: 24px;
    background-image: url("../assets/download_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .slogan-cn {
    font-size: 20px;
  }
  .slogan-en {
    font-size: 20px;
  }
  .logo {
    margin-bottom: 20px;
  }
  .bg-img {
    display: none;
  }
  .btn-img {
    width: 248px;
    cursor: pointer;
    height: auto;
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  #download {
    .header {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .name {
        border-bottom: 2px solid #8345e5;
      }
    }
    .slogan-cn {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .slogan-en {
      font-size: 14px;
    }
    .logo {
      width: 60px;
      height: auto;
      margin-bottom: 10px;
    }
    .bg-img {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      img {
        width: 160px;
        height: 228px;
        margin-top: 30px;
      }
    }
  }
}
</style>